import React from "react";
import { graphql } from 'gatsby'
import WhitePaperPreview from "../components/white-paper/whitePaperPreview";
import { resetWebflow } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import ReactMarkdown from "react-markdown";
import Notification from "../components/header/notification";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsWhitePaperMainArticle: {
      seoMetaTags,
      title,
      subtitle,
      previewSection,
      notificationPage
    },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  resetWebflow();
  previewSection.sort(({detail : a}, {detail: b}) => a.position - b.position);
  const previewSectionTmpl = previewSection.map(block => {    
    return <WhitePaperPreview data={block.detail} />;
  });
  return (
    <Layout>
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <HelmetDatoCms seo={seoMetaTags} />
      <header className="main-header about reducedbottom">
        <div className="container grid">
          <h1 className="h-nofixedwidth">{title}</h1>
          <div className="sm-spacing" />
          <div className="p center-align">
            <ReactMarkdown source={subtitle} escapeHtml={false} />
          </div>
          <div className="md-spacing" />
          {previewSectionTmpl}
        </div>
      </header>
    </Layout>
  );
};

export const query = graphql`
  query WhitePaper {
    datoCmsWhitePaperMainArticle {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
      notificationPage {
        id
        page
      }
      previewSection {
        detail {
          id
          title
          subtitle
          slug
          image {
            alt
            url
          }
          position
        }
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
