import React from "react";
import { Link } from "gatsby";

export default ({ data }) => {
  return (
    <React.Fragment>
      <Link to={`/whitepapers/${data.slug}`} className="_2cols-box w-inline-block">
        <div className="flex-container space-between">
          <img src={data.image.url} sizes="140px" className="book-cover-sm" alt={data.image.alt} />
          <div className="white-paper-content">
            <h5 className="h5">{data.title}</h5>
            <div className="tn-spacing" />
            <p className="small-copy">{data.subtitle}</p>
            <div className="sm-spacing" />
            <div className="link-text w-clearfix">
              <div className="learn-more">Download</div>
              <div className="arrow-right" />
            </div>
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
};
